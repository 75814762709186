import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_ACTIVITIES } from '../../common/translation';
import ActivitiesCard from '../../Component/ActivitiesCard';
import { CommonParagraphContainer } from '../../common/style/index.style';
import { ActivitiesCardContainer } from './index.style';
import { ACTIVITIES_DETAILS } from '../../common/routes';

function Activities(): JSX.Element {
  const [t] = useTranslation();

  const activitiesData = [
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
      url: ACTIVITIES_DETAILS,
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Signature Campaign on NITF 2015',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
      url: ACTIVITIES_DETAILS,
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
      url: ACTIVITIES_DETAILS,
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
      url: ACTIVITIES_DETAILS,
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
    {
      image:
        'http://www.savetheplanet.org.np/uploads/activities/widget/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg',
      title: 'Love The Nature',
      description:
        'Seventh annual celebration of Love The Nature has successfully organized at AITM building Khumaltar on the occasion of 14th February.',
    },
  ];
  const mappedActivities = activitiesData.map((elem) => (
    <ActivitiesCard
      image={elem.image}
      title={elem.title}
      description={elem.description}
      url={elem.url}
      key={uuid()}
    />
  ));
  const content = (
    <>
      <CommonParagraphContainer>
        <ActivitiesCardContainer>{mappedActivities}</ActivitiesCardContainer>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_ACTIVITIES)} content={content} />;
}

export default Activities;
