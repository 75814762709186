/* eslint-disable prettier/prettier */
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';
import { Overlay } from '../../common/style/index.style';
import {
  primaryColor,
  white,
  greyBorder,
  black,
  navShadow,
  navSubmenuColor,
  enhancedNavLinkStart,
  enhancedNavLinkEnd,
  yellowEnhancedLink,
} from '../../common/colors';
import { NavMenuProp, RootNavStyleProp } from '../../TsTypes';

const EnhancedFocusedNavItem = keyframes`
  0% {
    background: ${enhancedNavLinkStart};
    color: ${white};
  }
  100% {
    background: ${enhancedNavLinkEnd};
    color: ${white};
  }
`;

const menuFlowUp = keyframes`
  0% {
    display: flex;
    opacity: 0;
    transform: translateY(1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const subMenuFlowUp = keyframes`
  0% {
    display: flex;
    opacity: 0;
    transform: translateY(2em);
  }
  100% {
    opacity: 1;
    transform: translateY(0.5em);
  }
`;

export const NavLogo = styled.img`
  transition: all 0.2s;
  height: 40px;
  width: 56px;
  @media ${device.tablet} {
    height: 65px;
    width: 91px;
  }
`;

export const FloatingNavContainer = styled(Overlay)<NavMenuProp>`
  background-color: ${primaryColor};
  display: flex;
  flex-grow: 1;
  padding: 0em;
  justify-content: center;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  transition: 0.3s;
  z-index: 3;
  opacity: 0.98;
`;

export const FloatingNavOverlay = styled(Overlay)<{ inView: string }>`
  flex-direction: column;
  align-items: stretch;
  ${(prop) => ((prop.inView === true.toString()) ? 'padding: 1em 2em;' : 'padding: 1.3em 2.5em;')}
  ${(prop) => (!(prop.inView === true.toString()) ? `box-shadow: ${navShadow};` : 'box-shadow: none;')}
  transition : all 0.2s;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    ${(prop) => ((prop.inView === true.toString()) ? 'padding: 1em 2em;' : 'padding: 1.3em 4em;')}
  }
  @media ${device.laptop} {
    ${(prop) => ((prop.inView === true.toString()) ? 'padding: 1em 2em;' : 'padding: 1.3em 5em;')}
  }
`;

export const FloatingNavContent = styled(Overlay)`
  justify-content: space-between;
  padding: 0;
`;

export const MenuButton = styled.button`
  align-items: center;
  outline: 1px solid ${greyBorder};
  border: none;
  background: none;
  color: ${white};
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  padding: 0.1em 0.5em;
`;

export const NavClickMenuContainer = styled.section<NavMenuProp>`
  display: flex;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  flex-direction: column;
  animation: ${menuFlowUp} 0.3s forwards;
  @media ${device.tablet} {
    position: relative;
    top: 0;
    width: auto;
    left: 0;
    animation: none;
  }
`;

export const NavClickMenuOverlay = styled.section<NavMenuProp>`
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${primaryColor};
  outline: 1px solid ${greyBorder};
  @media ${device.tablet} {
    flex-direction: row;
    outline: none;
  }
`;

export const NavItemContainer = styled.section`
  border: none;
  display: flex;
  flex-grow: 1;
  position: relative;
  color: ${white};
  flex-direction: column;
`;

export const ParentNavItem = styled.button<RootNavStyleProp>`
  background: none;
  border: none;
  display: flex;
  flex-grow: 1;
  color: white;
  align-items: center;
  padding: 0.75em 1.25em;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.4s;
  font-weight: 600;
  ${(prop) => {
    const renderProp = prop.isMenuOpen
      ? `background-color: ${greyBorder}; color: ${black};`
      : '';
    return renderProp;
  }}
  &:hover {
    background-color: ${greyBorder};
    color: ${black};
  }
`;

export const NavItemLink = styled(NavLink)<{ focus: string | undefined }>`
  align-items: center;
  border: none;
  display: flex;
  flex-grow: 1;
  position: relative;
  color: white;
  padding: 0.75em 1.25em;
  border-radius: 4px;
  text-align: start;
  align-items: center;
  height: auto;
  margin: 0em 0.1em;
  transition: all 0.4s;
  background: ${(prop) => (prop.focus === 'true' ? yellowEnhancedLink : 'none')};
  color: ${(prop) => (prop.focus === 'true' ? black : white)};
  @media ${device.tablet} {
    width: auto;
  }
  &:hover {
    background-color: ${greyBorder};
    color: ${black};
    animation: none;
  }
`;

export const ChildNavItemContainer = styled.section<any>`
  border: none;
  position: absolute;
  top: 100%;
  display: block;
  flex-direction: column;
  ${(prop) => `z-index: ${prop.index};`}
  left: 50%;
  width: calc(100% - 1em);
  transform: translateX(-50%) translateY(0%);
  border-radius: 4px;
  @media ${device.tablet} {
    position: absolute;
    width: max-content;
  }
`;

export const ChildNavItem = styled.section`
  background: ${navSubmenuColor};
  border-radius: 4px;
  animation: ${subMenuFlowUp} 0.3s forwards;
  outline: 1px solid ${greyBorder};
  height: 100%;
`;
