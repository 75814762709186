import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_GALLERY } from '../../common/translation';
import { GALLERY_TESTIMONIALS } from '../../common/routes';
import GalleryCard from '../../Component/Gallery';
import { GalleryCardContainer } from './index.style';
import { CommonParagraphContainer } from '../../common/style/index.style';

const GalleryData = [
  {
    thumbnailURL:
      'http://www.savetheplanet.org.np/uploads/gallery/S_P01CDSglgjqUi13fSucFU-ZtX5cETU.jpg',
    title: 'This is a very long testimonial Testimonials',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'http://www.savetheplanet.org.np/uploads/gallery/S_P01CDSglgjqUi13fSucFU-ZtX5cETU.jpg',
    title: 'Highway Greenery Project',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'http://www.savetheplanet.org.np/uploads/gallery/S_P01CDSglgjqUi13fSucFU-ZtX5cETU.jpg',
    title: 'Testimonials',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'http://www.savetheplanet.org.np/uploads/gallery/S_P01CDSglgjqUi13fSucFU-ZtX5cETU.jpg',
    title: 'Testimonials',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'http://www.savetheplanet.org.np/uploads/gallery/S_P01CDSglgjqUi13fSucFU-ZtX5cETU.jpg',
    title: 'Testimonials',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
];

function Gallery(): JSX.Element {
  const [t] = useTranslation();
  const MappedGalleryData = GalleryData.map((elem) => (
    <GalleryCard
      thumbnailURL={elem.thumbnailURL}
      title={elem.title}
      url={elem.url}
      key={uuid()}
    />
  ));
  const content = (
    <>
      <CommonParagraphContainer>
        <GalleryCardContainer>{MappedGalleryData}</GalleryCardContainer>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_GALLERY)} content={content} />;
}

export default Gallery;
