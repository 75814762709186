/* eslint-disable prettier/prettier */
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';
import { Overlay, Container } from '../../common/style/index.style';
import {
  primaryColor,
  white,
  greyBorder,
  black,
  navShadow,
  navSubmenuColor,
  enhancedNavLinkStart,
  enhancedNavLinkEnd,
} from '../../common/colors';

export const CarouselLegend = styled.p`
  color: red !important;
`;

export const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 3em;
  background-color: transparent;
  top: 0;
  z-index: 2;
  border: none;
  color: white;
`;

export const NavIconOverlay = styled.div`
  position: absolute;
  height: 100%;
  opacity: 0.09;
  width: 3em;
  background-color: ${white};
  top: 0;
  filter: drop-shadow(2px 4px 6px ${black});
  transition: all 0.2s;
  &:hover{
    opacity: 0.2;
  }
`;

export const HomeBodyContainer = styled(Container)`
  background: transparent;
`;

export const HomeBannerContainer = styled(Overlay)`
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  flex-wrap: wrap;
  flex-direction: column;
 @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

export const HomeBanner = styled(NavLink)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 1em;
  flex-basis: 0;
  transition: all 0.4s;
  &:hover {
    box-shadow: ${navShadow};
  }
  @media ${device.mobileLarge} {
    flex-basis: 33%;
  }
`;

export const HomeBannerItemContainer = styled.section`
  width: 100%;
  @media ${device.mobileLarge} {
    width: 50%;
  }
`;

export const NewsAndEventMore = styled(NavLink)`
  background: ${navSubmenuColor};
  color: white;
  padding: 0.5em 2em;
  transition: all 0.4s;
  &:hover {
    background: ${enhancedNavLinkEnd};
  }
`;
