/* eslint-disable operator-linebreak */
import { MouseEventHandler, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { v4 as uuid } from 'uuid';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import {
  NavButton,
  NavIconOverlay,
  CarouselImage,
  CarouselImageContainer,
} from './index.style';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { prevButton, nextButton } from '../../common/aria';

const DisplayCarousel = ({
  images,
  options,
  imageClickHandler,
  isImageOpen,
  selectedItem,
}: {
  images?: {
    url?: string;
    alternate?: string;
    displayMax?: string;
  }[];
  options?: {
    showThumbnail?: boolean;
    width?: string;
    selectedItem?: number;
  };
  selectedItem?: number;
  isImageOpen?: string;
  imageClickHandler?: (val: MouseEventHandler<HTMLDivElement>) => void;
}): JSX.Element => {
  const renderArrowPrev = (clickHandler: () => void) => (
    <NavButton
      aria-label={prevButton}
      onClick={clickHandler}
      style={{
        left: 0,
      }}
      id="desiredCarouselClick"
    >
      <NavIconOverlay
        style={{
          left: 0,
        }}
        id="desiredCarouselClick"
      ></NavIconOverlay>
      <MdArrowBackIos
        style={{ fontSize: '2em', filter: 'invert(1)' }}
        id="desiredCarouselClick"
      />
    </NavButton>
  );
  const renderArrowNext = (clickHandler: () => void) => (
    <NavButton
      aria-label={nextButton}
      onClick={clickHandler}
      style={{
        right: 0,
      }}
      id="desiredCarouselClick"
    >
      <NavIconOverlay
        style={{
          right: 0,
          filter: 'drop-shadow(2px 4px 6px black)',
        }}
        id="desiredCarouselClick"
      ></NavIconOverlay>
      <MdArrowForwardIos
        style={{ fontSize: '2em', filter: 'invert(1)' }}
        id="desiredCarouselClick"
      />
    </NavButton>
  );

  const MappedImages = images?.map((elem) => (
    <CarouselImageContainer
      key={uuid()}
      id="desiredCarouselClick"
      displayMax={elem.displayMax}
    >
      <CarouselImage
        id="desiredCarouselClick"
        src={elem.url || '/'}
        alt={elem.alternate}
        displayMax={elem.displayMax}
      />
    </CarouselImageContainer>
  ));

  return (
    <Carousel
      infiniteLoop
      useKeyboardArrows
      autoPlay={false}
      swipeable={true}
      showThumbs={false}
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      transitionTime={500}
      interval={4000}
      dynamicHeight={false}
      verticalSwipe={'natural'}
      selectedItem={selectedItem}
    >
      {MappedImages}
    </Carousel>
  );
};

export default DisplayCarousel;
