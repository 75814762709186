import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Overlay, maxWidth } from '../../common/style/index.style';
import {
  HomeBodyContainer,
  HomeBannerContainer,
  HomeBanner,
  NewsAndEventMore,
  HomeBannerItemContainer,
} from './index.style';
import Carousel from '../../Component/Carousel';
import { NavAndEventContainer } from '../NewsAndEvents/index.style';
import NewsEentCard from '../../Component/NewsAndEvents';
import {
  NEWS_AND_EVENTS,
  SCHOOL_GREEN,
  LOVE_THE_NATURE,
  NEWS_AND_EVENTS_BOSTON,
  NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
} from '../../common/routes';

const eventsData = [
  {
    title: 'IUCN World Conservation Congress 2020',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
    image:
      'http://www.savetheplanet.org.np/uploads/news/-xGvEll65-kmQdfg8cpLpS3nPV0L0_UL.jpg',
  },
  {
    title: '12th Annual Boston Green Fest',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_BOSTON,
    image:
      'http://www.savetheplanet.org.np/uploads/news/8I1w3PbgcBxquVzQVRpwyAGmxVKQ5MJ0.jpg',
  },
];

const Home = (): JSX.Element => {
  const [isLiading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = 'Home | Save The Planet';
  }, []);

  const MappedNewsCard = eventsData.map((elem) => (
    <NewsEentCard
      title={elem.title}
      dated={elem.dated}
      url={elem.url}
      image={elem.image}
      key={uuidv4()}
    />
  ));

  const handleLoadingComplete = () => {
    setIsLoading(!isLiading);
  };

  const Images = [
    {
      url: 'http://www.savetheplanet.org.np/uploads/banner/QJszWqFpQsVw0w-uPZ8vDr7vvmpfQEIt.jpg',
      alternate: 'Images',
    },
    {
      url: 'http://www.savetheplanet.org.np/uploads/banner/0GYciPHsNHHb_fa_wxd_fS-zwY02QeqB.jpg',
      alternate: 'Images',
    },
    {
      url: 'http://www.savetheplanet.org.np/uploads/banner/6qxQVy3VmD9yfvnFNQuqmUIY4rGwq_Eh.jpg',
      alternate: 'Images',
    },
    {
      url: 'http://www.savetheplanet.org.np/uploads/banner/N7BdnrrkQ7itkltWGYAXXJrSM0_LjuYV.jpg',
      alternate: 'Images',
    },
    {
      url: 'http://www.savetheplanet.org.np/uploads/banner/q4lSgcdhxDybmDVm6LxR2--Wddd2SX05.jpg',
      alternate: 'Images',
    },
  ];

  return (
    <Overlay
      style={{
        padding: 0,
        flexDirection: 'column',
        fontWeight: '100',
        fontSize: '1rem',
      }}
    >
      <Carousel images={Images} />
      {isLiading && <div>LOADING........</div>}
      <HomeBodyContainer>
        <Overlay
          style={{
            maxWidth: `${maxWidth}`,
            flexDirection: 'column',
          }}
        >
          <h1>Welcome</h1>
          <h2>Our Campaigns</h2>
          <HomeBannerContainer>
            <HomeBannerItemContainer>
              <HomeBanner to={LOVE_THE_NATURE}>
                <img
                  src="http://www.savetheplanet.org.np/uploads/pages/loveNature.jpg"
                  alt="Love Nature"
                  style={{ width: '100%' }}
                  onLoad={handleLoadingComplete}
                />
              </HomeBanner>
            </HomeBannerItemContainer>
            <HomeBannerItemContainer>
              <HomeBanner to={SCHOOL_GREEN}>
                <img
                  src="http://www.savetheplanet.org.np/uploads/pages/greenCampaign.jpg"
                  alt="School Green"
                  style={{ width: '100%' }}
                />
              </HomeBanner>
            </HomeBannerItemContainer>
          </HomeBannerContainer>
          <section
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h2 style={{ display: 'inline-block' }}>News</h2>
            <NewsAndEventMore to={NEWS_AND_EVENTS}>More</NewsAndEventMore>
          </section>
          <HomeBannerContainer>
            <NavAndEventContainer>{MappedNewsCard}</NavAndEventContainer>
          </HomeBannerContainer>
        </Overlay>
      </HomeBodyContainer>
    </Overlay>
  );
};

export default Home;
