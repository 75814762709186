import { useTranslation } from 'react-i18next';
import { MdEvent } from 'react-icons/md';
import GenericPage from '../../Generic';
import { HEADER_BOSTON } from '../../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  EventDateContainer,
} from '../../../common/style/index.style';

function NewsAndEventsBoston(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <img
          src="http://www.savetheplanet.org.np/uploads/news/8I1w3PbgcBxquVzQVRpwyAGmxVKQ5MJ0.jpg"
          alt="12th Annual Boston Green Fest"
          style={{ width: '100%', height: 'auto', borderRadius: '1em' }}
        />
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          <EventDateContainer>
            <MdEvent style={{ fontSize: '2em' }} />
            <span>6 May 2019</span>
          </EventDateContainer>
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          Boston GreenFest is a grassroots effort organized by the{' '}
          <a href="https://www.foundationforagreenfuture.org/">
            Foundation for a Green Future
          </a>
          and its dedicated team of community volunteers. Our goal is to educate
          and empower people to create a more sustainable, healthier world. We
          are actively building an interconnected, ever expanding network. From
          individuals young to old, business to nonprofit, neighborhood
          association to academic institution, Boston GreenFest welcomes
          everyone! Whether an emerging start-up or established Fortune 500
          business, your sustainability message will gain meaningful widespread
          visibility with our festival attendees. Our event will also feature
          the Massachusetts GreenTech Expo on Saturday, August 17th which will
          be open to the public.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_BOSTON)} content={content} />;
}

export default NewsAndEventsBoston;
