import i18next from 'i18next';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { device } from '../../MediaQuery/MediaQuery';
import {
  ParentNavItem,
  NavItemContainer,
  ChildNavItemContainer,
  NavItemLink,
  ChildNavItem,
} from './index.style';
import { NestedNavItems } from '../../TsTypes';
import {
  SAVE_THE_PLANET,
  MISSION_AND_VISION,
  OUR_GOALS,
  ADVISORY_TEAM_LINK,
  MANAGEMENT_TEAM_LINK,
  INTERNATIONAL_COORDINATION_LINK,
  SCHOOL_GREEN,
  LOVE_THE_NATURE,
  ACTIVITIES,
  VOLUNTEERS,
  CONTACT,
  VOLUNTEER,
} from '../../common/routes';

const ManageNestedNavItems = ({
  title,
  navChildren,
  url,
  translate,
  index,
  icon,
  focus,
}: NestedNavItems): JSX.Element => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [t] = useTranslation();
  const isTabletUp = useMediaQuery({
    query: device.tablet,
  });

  const handleSubMenuOpen = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  const handleMenuBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isSubMenuOpen) {
        setIsSubMenuOpen(!isSubMenuOpen);
      }
    }
  };
  const handleMenuEnter = () => {
    if (!isSubMenuOpen && isTabletUp) {
      setIsSubMenuOpen(!isSubMenuOpen);
    }
  };
  const handleMenuLeave = () => {
    if (isSubMenuOpen && isTabletUp) {
      setIsSubMenuOpen(!isSubMenuOpen);
    }
  };
  if (navChildren && navChildren.length) {
    const MappedChildren = navChildren.map((x, elemIndex) => (
      <ManageNestedNavItems
        key={uuidv4()}
        title={x.title}
        navChildren={x.navChildren}
        url={x.url}
        icon={x.icon}
        focus={x.focus}
        index={(index || 0) * 10 + (navChildren.length - elemIndex)}
        translate={translate}
      />
    ));
    return (
      <NavItemContainer
        onMouseEnter={handleMenuEnter}
        onMouseLeave={handleMenuLeave}
        onBlur={handleMenuBlur}
      >
        <ParentNavItem onClick={handleSubMenuOpen} isMenuOpen={isSubMenuOpen}>
          <span>{(translate && t(title)) || title}</span>
          <MdArrowDropDown
            style={{
              paddingLeft: '0.5em',
              fontSize: '1.5em',
              opacity: `${isSubMenuOpen ? '0' : '1'}`,
            }}
          />
        </ParentNavItem>
        {isSubMenuOpen && (
          <ChildNavItemContainer
            className={'ChildNavItemContainer'}
            index={index}
            isSubMenuOpen={isSubMenuOpen}
          >
            <ChildNavItem className={'ChildNavItem'}>
              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transform: 'translateY(-1em)',
                  position: 'absolute',
                  width: '100%',
                }}
              >
                <MdArrowDropUp style={{ fontSize: '1.5em' }} />
              </section>
              {MappedChildren}
            </ChildNavItem>
          </ChildNavItemContainer>
        )}
      </NavItemContainer>
    );
  }
  return (
    <NavItemLink to={url || '/'} focus={focus}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {(translate && t(title)) || title} {icon}
      </span>
    </NavItemLink>
  );
};

const NavItem = (): JSX.Element => {
  // POSSIBLY FROM STORE
  const NavURLS = [
    {
      title: 'NAV.NAV_ABOUT',
      navChildren: [
        {
          title: 'NAV.NAV_STP',
          url: SAVE_THE_PLANET,
        },
        {
          title: 'NAV.NAV_MV',
          url: MISSION_AND_VISION,
        },
        {
          title: 'NAV.NAV_OG',
          url: OUR_GOALS,
        },
        {
          title: 'NAV.NAV_AT',
          url: ADVISORY_TEAM_LINK,
        },
        {
          title: 'NAV.NAV_MT',
          url: MANAGEMENT_TEAM_LINK,
        },
        {
          title: 'NAV.NAV_IC',
          url: INTERNATIONAL_COORDINATION_LINK,
        },
      ],
    },
    {
      title: 'NAV.NAV_CAMPAIGN',
      navChildren: [
        {
          title: 'NAV.NAV_SG',
          url: SCHOOL_GREEN,
        },
        {
          title: 'NAV.NAV_LTN',
          url: LOVE_THE_NATURE,
        },
      ],
    },
    {
      title: 'NAV.NAV_ACTIVITIES',
      url: ACTIVITIES,
    },
    {
      title: 'NAV.NAV_VOLUNTEERS',
      url: VOLUNTEERS,
    },
    {
      title: 'NAV.NAV_CONTACT',
      url: CONTACT,
    },
    {
      title: 'NAV.NAV_VOLUNTEER',
      url: VOLUNTEER,
      focus: 'true',
      icon: (
        <AiOutlineUsergroupAdd
          style={{ padding: '0em 0.5em', fontSize: '1.5em' }}
        />
      ),
    },
  ];

  function handleLocaleChangeNp(e: any) {
    e.preventDefault();
    i18next.changeLanguage('np');
  }
  function handleLocaleChangeEn(e: any) {
    e.preventDefault();
    i18next.changeLanguage('en');
  }
  const MappedNavItems = NavURLS.map((elem: NestedNavItems, index: number) => (
    <ManageNestedNavItems
      key={uuidv4()}
      title={elem.title}
      navChildren={elem.navChildren}
      url={elem.url}
      icon={elem.icon}
      focus={elem.focus}
      index={NavURLS.length - index}
      translate={true}
    />
  ));
  return <>{MappedNavItems}</>;
};

export default NavItem;
