import { useTranslation } from 'react-i18next';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import GenericPage from '../Generic';
import { HEADERR_NEWS_AND_EVENTS } from '../../common/translation';
import NewsEentCard from '../../Component/NewsAndEvents';
import { NavAndEventContainer } from './index.style';
import {
  NEWS_AND_EVENTS_BOSTON,
  NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
} from '../../common/routes';

const eventsData = [
  {
    title: 'IUCN World Conservation Congress 2020',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
    image:
      'http://www.savetheplanet.org.np/uploads/news/-xGvEll65-kmQdfg8cpLpS3nPV0L0_UL.jpg',
  },
  {
    title: '12th Annual Boston Green Fest',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_BOSTON,
    image:
      'http://www.savetheplanet.org.np/uploads/news/8I1w3PbgcBxquVzQVRpwyAGmxVKQ5MJ0.jpg',
  },
];
function NewsAndEvents(): JSX.Element {
  const [t] = useTranslation();
  const MappedNewsCard = eventsData.map((elem) => (
    <NewsEentCard
      title={elem.title}
      dated={elem.dated}
      url={elem.url}
      image={elem.image}
      key={uuidv4()}
    />
  ));
  const content = <NavAndEventContainer>{MappedNewsCard}</NavAndEventContainer>;
  return <GenericPage title={t(HEADERR_NEWS_AND_EVENTS)} content={content} />;
}

export default NewsAndEvents;
