import styled from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';

export const ContentBody = styled.section`
  position: relative;
  z-index: 0;
  text-align: justify;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    background-image: url(/Images/logo.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    filter: opacity(0.2) blur(0.3em);
    z-index: -1;
    @media ${device.mobileSmall} {
      filter: opacity(0.2) blur(0.6em);
    }
    @media ${device.mobileLarge} {
      filter: opacity(0.2) blur(0.8em);
    }
    @media ${device.tablet} {
      filter: opacity(0.2) blur(1.3em);
    }
  }
`;

export const InformationTitle = styled.td`
  padding-right: 0.5em;
`;

export const InformationContainer = styled.tr`
  padding-top: 0.25em;
  padding-bottom: 0.25em;
`;
