import { useTranslation } from 'react-i18next';
import { MdEvent } from 'react-icons/md';
import GenericPage from '../../Generic';
import { HEADER_IUCN_WORLD_CONSERVATION } from '../../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  EventDateContainer,
} from '../../../common/style/index.style';

function SchoolGreen(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <img
          src="http://www.savetheplanet.org.np/uploads/news/-xGvEll65-kmQdfg8cpLpS3nPV0L0_UL.jpg"
          alt="IUCN World Conservation Congress"
          style={{ width: '100%', height: 'auto', borderRadius: '1em' }}
        />
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          <EventDateContainer>
            <MdEvent style={{ fontSize: '2em' }} />
            <span>6 May 2019</span>
          </EventDateContainer>
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          The next IUCN World Conservation Congress will take place from 11 to
          19 June 2020, in Marseille, France. With 2020 a pivotal year for
          framing the next decade, and with participants from some of the most
          influential governments and organisations in the world, the IUCN
          Congress is a fantastic opportunity to showcase your work, share your
          views, reactivate your networks, and inspire action for conservation.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return (
    <GenericPage title={t(HEADER_IUCN_WORLD_CONSERVATION)} content={content} />
  );
}

export default SchoolGreen;
