/* eslint-disable prettier/prettier */
import { initReactI18next } from 'react-i18next';
import React, { Fragment, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import detector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import translationObjectEn from './Translation/TranslationEn.json';
import translationObjectNp from './Translation/TranslationNp.json';
import './css/App.css';
import './css/normalize.css';
import Layout from './Layout';
import {
  Home,
  NotFound,
  SaveThePlanet,
  MissionAndVision,
  GoalsAndObjectives,
  Teams,
  LoveTheNature,
  SchoolGreen,
  Activities,
  Volunteers,
  Contact,
  Volunteer,
  Gallery,
  NewsAndEvents,
  NewsAndEventsBoston,
  NewsAndEventsIUCNWorldConservation,
  ActivitiesLoveTheNature,
  GalleryTestimonials,
} from './Page/index';
import {
  ROOT,
  SAVE_THE_PLANET,
  WILDCARD,
  MISSION_AND_VISION,
  OUR_GOALS,
  SCHOOL_GREEN,
  LOVE_THE_NATURE,
  ACTIVITIES,
  VOLUNTEERS,
  CONTACT,
  VOLUNTEER,
  GALLERY,
  NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_BOSTON,
  NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
  TEAM,
  ACTIVITIES_DETAILS,
  GALLERY_TESTIMONIALS,
} from './common/routes';

const resources = {
  np: {
    translation: translationObjectNp,
  },
  en: {
    translation: translationObjectEn,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    supportedLngs: ['en', 'np'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

const App: React.FunctionComponent = () => (
  <Fragment>
    <Suspense fallback={'Locafing'}>
      <Fragment>
        <Router>
          <Routes>
            <Route
              path={ROOT}
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path={SAVE_THE_PLANET}
              element={
                <Layout>
                  <SaveThePlanet />
                </Layout>
              }
            />
            <Route
              path={MISSION_AND_VISION}
              element={
                <Layout>
                  <MissionAndVision />
                </Layout>
              }
            />
            <Route
              path={OUR_GOALS}
              element={
                <Layout>
                  <GoalsAndObjectives />
                </Layout>
              }
            />
            <Route
              path={TEAM}
              element={
                <Layout>
                  <Teams />
                </Layout>
              }
            />
            <Route
              path={LOVE_THE_NATURE}
              element={
                <Layout>
                  <LoveTheNature />
                </Layout>
              }
            />
            <Route
              path={SCHOOL_GREEN}
              element={
                <Layout>
                  <SchoolGreen />
                </Layout>
              }
            />
            <Route
              path={ACTIVITIES}
              element={
                <Layout>
                  <Activities />
                </Layout>
              }
            />
            <Route
              path={VOLUNTEERS}
              element={
                <Layout>
                  <Volunteers />
                </Layout>
              }
            />
            <Route
              path={CONTACT}
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route
              path={VOLUNTEER}
              element={
                <Layout>
                  <Volunteer />
                </Layout>
              }
            />
            <Route
              path={GALLERY}
              element={
                <Layout>
                  <Gallery />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS}`}
              element={
                <Layout>
                  <NewsAndEvents />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION}`}
              element={
                <Layout>
                  <NewsAndEventsIUCNWorldConservation />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS_BOSTON}`}
              element={
                <Layout>
                  <NewsAndEventsBoston />
                </Layout>
              }
            />
            <Route
              path={WILDCARD}
              element={
                <Layout>
                  <NotFound />
                </Layout>
              }
            />
            <Route
              path={ACTIVITIES_DETAILS}
              element={
                <Layout>
                  <ActivitiesLoveTheNature />
                </Layout>
              }
            />
            <Route
              path={GALLERY_TESTIMONIALS}
              element={
                <Layout>
                  <GalleryTestimonials />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </Fragment>
    </Suspense>
  </Fragment>
);

export default App;
