/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { maxWidth } from '../../common/style/index.style';
import {
  white,
  black,
} from '../../common/colors';
import { device } from '../../MediaQuery/MediaQuery';

export const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 3em;
  background-color: transparent;
  top: 0;
  z-index: 2;
  border: none;
  color: white;
  cursor: pointer;
`;

export const NavIconOverlay = styled.div`
  position: absolute;
  height: 100%;
  opacity: 0.09;
  width: 3em;
  background-color: ${white};
  top: 0;
  filter: drop-shadow(2px 4px 6px ${black});
  transition: all 0.2s;
  @media ${device.tablet} {
    width: 10em;
  }
  &:hover{
    opacity: 0.2;
  }
`;

export const CarouselImage = styled.img<{displayMax?: string}>`
  max-width: ${(prop) => {
    if (prop.displayMax && prop.displayMax === true.toString()) {
      return maxWidth;
    }
    return 'auto';
  }};
background: ${(prop) => {
    if (prop.displayMax && prop.displayMax === true.toString()) {
      return 'red';
    }
    return 'black';
  }};
`;

export const CarouselImageContainer = styled.button<{ displayMax?: string }>`
  width: ${(prop) => {
    if (prop.displayMax && prop.displayMax === true.toString()) {
      return 'auto';
    }
    return '100%';
  }};
  border: none;
`;
