/* eslint-disable operator-linebreak */
import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import {
  GalleryDetailContainer,
  GalleryImage,
  GalleryCarouselContainer,
} from './index.style';
import { CommonParagraphContainer } from '../../../common/style/index.style';
import GenericPage from '../../Generic';
import Carousel from '../../../Component/Carousel';

const DetailsPageData = [
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
  {
    url: 'http://www.savetheplanet.org.np/uploads/photos/vL5_7yaC4E160321085455.jpg',
    alternate: 'Testimonial',
    displayMax: true.toString(),
  },
];

const GalleryDetail = (): JSX.Element => {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleImageClick = (elementIndex: number) => {
    setIsImageOpen(!isImageOpen);
    setSelectedItem(elementIndex);
  };

  const clickHandle = (event: any) => {
    if (
      !(
        event.target.id === 'desiredCarouselClick' ||
        event.target.className === 'control-dots' ||
        event.target.className === 'dot selected' ||
        event.target.nodeName.toUpperCase() === 'SVG' ||
        event.target.nodeName.toUpperCase() === 'PATH' ||
        event.target.nodeName.toUpperCase() === 'IMG'
      )
    ) {
      window.removeEventListener('click', clickHandle);
      setIsImageOpen(!isImageOpen);
    }
  };

  useEffect(() => {
    if (isImageOpen) {
      document.body.classList.add('disableOverflow');
      window.addEventListener('click', clickHandle);
    } else {
      document.body.classList.remove('disableOverflow');
    }
  }, [isImageOpen]);

  useEffect(() => {
    const handlePopHistory = () => {
      window.removeEventListener('click', clickHandle);
      window.removeEventListener('popstate', handlePopHistory);
      document.body.classList.remove('disableOverflow');
    };

    window.addEventListener('popstate', handlePopHistory);
  }, []);

  const MappedImages = DetailsPageData.map((elem, elementIndex) => (
    <GalleryImage
      onClick={() => handleImageClick(elementIndex)}
      src={elem.url}
      alt={elem.alternate || 'Testimonial'}
      key={uuid()}
    />
  ));

  const content = (
    <>
      <CommonParagraphContainer>
        <GalleryDetailContainer>{MappedImages}</GalleryDetailContainer>
        {isImageOpen && (
          <GalleryCarouselContainer isItemClicked={isImageOpen.toString()}>
            <Carousel
              images={DetailsPageData}
              isImageOpen={isImageOpen.toString()}
              selectedItem={selectedItem}
            />
          </GalleryCarouselContainer>
        )}
      </CommonParagraphContainer>
    </>
  );

  return (
    <GenericPage
      title="Testimonials"
      content={content}
      disableContentAnimation={isImageOpen.toString()}
    />
  );
};

export default GalleryDetail;
