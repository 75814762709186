import styled from 'styled-components';
import { pageTitleColor, primaryColor, navSubmenuColor } from '../colors';

export const Overlay = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 1em;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Figure = styled.figure`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const maxWidth = '1170px';

export const CommonParagraphContainer = styled.section`
  padding: 0.75em;
`;

export const CommonParagraph = styled.p`
  padding: 0.5em;
`;

export const EventDateContainer = styled.span`
  display: flex;
  flec-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const UnorderedCommonList = styled.ul<{ lineStyle?: string }>`
  list-style-type: ${(prop) => prop.lineStyle || 'none'};
  padding: 0.25em 1em;
`;

export const OrderedCommonList = styled.ul<{ lineStyle?: string }>`
  list-style-type: ${(prop) => prop.lineStyle || 'decimal'};
  padding: 0.25em 1.5em;
`;

export const CommonListItem = styled.li`
  padding: 0.25em 1em;
`;

export const ParagraphHeader = styled.h3`
  color: ${pageTitleColor};
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
`;

export const InputFieldContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  flex-direction: column;
  padding: 0.5em;
`;

export const FormField = styled.input<{
  placeholderString?: string;
  type?: string;
}>`
  placeholder: ${(prop) => prop.placeholderString};
  padding: 0.5em;
  display: flex;
  flex-grow: 1;
  transition: all 0.4s;
  ${(prop) => {
    if (prop.type === 'submit') {
      return `cursor: pointer;
        border: none;
        background: ${primaryColor};
        color: white;
        padding: 1em;
        font-weight: bold;
        width: 50%;
        justify-content: center;
        &:hover{
          background: ${navSubmenuColor};
        }
        `;
    }
    return '';
  }}
`;

export const FormFieldTextArea = styled.textarea<{
  placeholderString?: string;
}>`
  placeholder: ${(prop) => prop.placeholderString};
  padding: 0.5em;
  display: flex;
  flex-grow: 1;
  height: 8em;
  resize: vertical;
`;
