import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_STP } from '../../common/translation';
import {
  CommonParagraph,
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';
import {
  ContentBody,
  InformationTitle,
  InformationContainer,
} from './index.style';

const InformationTable = ({
  title,
  value,
}: {
  title?: string;
  value?: string;
}) => (
  <InformationContainer>
    <InformationTitle>
      <span>
        <em>{title}:</em>
      </span>
    </InformationTitle>
    <InformationTitle>
      <span style={{ wordBreak: 'break-all' }}>{value}</span>
    </InformationTitle>
  </InformationContainer>
);

function SaveThePlanet(): JSX.Element {
  const [t] = useTranslation();
  const generalInformation = [
    { title: 'Government Regd. No.', value: ' 341' },
    { title: 'Date of Regd.', value: '2064/01/02( 2007)' },
    { title: 'PAN No.', value: '302653216' },
    { title: 'SWC Affiliation No.', value: '22748' },
    { title: 'NGO Federation No.', value: 'ER-PAN-73' },
    { title: 'Head Office', value: 'Phidim-1, Panchthar, NEPAL' },
    { title: 'Branches ', value: 'Panchthar, Kathmandu, Jhapa, Lalitpur.' },
    {
      title: 'Mailing Address',
      value: 'P.O. Box. - 8975 EPC 5010, Kumaripati, Lalitpur, NEPAL',
    },
    { title: 'Acting Committee', value: ' Nine Members.' },
    { title: 'General Member', value: '165' },
    { title: 'Permanent Staffs', value: ' 18' },
    { title: 'Volunteers', value: '20 to 30 yearly as necessary' },
    { title: 'Contact Person', value: ' Mr. Yadav Bhandari (9841-334022)' },
    {
      title: 'Email',
      value: ' info@savetheplanet.org.np/ savetheplanetnepal@gmail.com',
    },
    { title: 'URL', value: 'www.savetheplanet.org.np' },
  ];
  const GeneralInformationRows = generalInformation.map((elem) => (
    <InformationTable title={elem.title} value={elem.value} key={uuidv4()} />
  ));
  const content = (
    <ContentBody>
      <CommonParagraphContainer>
        <ParagraphHeader>Introduction</ParagraphHeader>
        <CommonParagraph>
          <span style={{ fontWeight: 'bold' }}>
            SAVE THE PLANET mission2020 NEPAL
          </span>
          is a non-profit making organization incepted with a view to work in
          the field of Environment in 2064 B.S./2007 A.D. Having observed the
          deteriorating condition of the earth in terms of environmental
          degradation and global warming, different experienced personalities
          and youths, with a vision to save the planet by loving the nature,
          have jointly established this organization in order to aware the
          people and involve them in the activities it carries throughout the
          nation and international scenario as well. Since its establishment, it
          has been working in the field of environmental issues with different
          activities related to its objectives.
        </CommonParagraph>
        <CommonParagraph>
          Although Nepal is bit backward in technical and material development,
          it is essential to conserve the natural resources and maintain the
          environmental balance for its culmination to attract the attention of
          the world.
        </CommonParagraph>
        <CommonParagraph>
          Taking right support, suggestion and assistance from national and
          international organizations for research of this sector to make the
          model prosperous, Nepalese communities in the world by conserving and
          optimum utilization of the natural resources is the motto of this
          organization.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>MISSION</ParagraphHeader>
        <CommonParagraph>
          Make Nepal a beautiful and prosperous in terms of environmental
          conservation by mobilizing the local, national and international
          resources so far and promote sustainable development.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>VISION</ParagraphHeader>
        <CommonParagraph>
          Conserve, develop and utilize the natural resources sustainably and
          keep balance in biological diversities in maximal, create a balanced
          nature on the basis of the right of the declining plants and animals.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>GOALS</ParagraphHeader>
        <CommonParagraph>
          Protect environment, conserve natural resources, keep beauty of the
          planet and create a conducive environment to live in for the future
          generation.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>OBJECTIVES:</ParagraphHeader>
        <OrderedCommonList>
          <CommonListItem>
            To conserve and promote extinctive animals and plants.
          </CommonListItem>
          <CommonListItem>
            To empower people of all classes, levels and areas to conserve
            biological diversities.
          </CommonListItem>
          <CommonListItem>
            To conserve, develop and widen plant community.
          </CommonListItem>
          <CommonListItem>
            Use fundamental principle of livelihood to conserve natural
            resources and utility.
          </CommonListItem>
          <CommonListItem>
            To construct profitless, to keep natural balance by utilizing the
            resources of educational institutions, health&nbsp;posts and develop
            public parks to inform and comfort to different community.
          </CommonListItem>
          <CommonListItem>
            To conduct world-wide programmes related to environmental balance
            and conserve by 2020 A.D.
          </CommonListItem>
          <CommonListItem>
            To establish and operate centers in research of natural resources
            that can be produced in the concerned&nbsp;areas by advocating and
            supporting Human Rights.
          </CommonListItem>
          <CommonListItem>
            To conduct specific self dependence program focusing women’s and
            children’s potential and aware them to&nbsp;generate income by
            maintaining the environmental conservation.
          </CommonListItem>
        </OrderedCommonList>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>General Information</ParagraphHeader>
        <table style={{ padding: '0.5em' }}>
          <tbody>{GeneralInformationRows}</tbody>
        </table>
      </CommonParagraphContainer>
    </ContentBody>
  );
  return <GenericPage title={t(HEADER_STP)} content={content} />;
}

export default SaveThePlanet;
