import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_LTN } from '../../common/translation';
import {
  CommonParagraph,
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function LoveTheNature(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>Introduction</ParagraphHeader>
        <CommonParagraph>
          This is the need of the hour to educate and aware general public on
          environmental issues faced by our nation and the world. What will be a
          better place to initiate than the urban area where most population is
          accumulated? Due to industrial and vehicle smoke human health is
          affecting day by day. Due to concretization beauty of city is being
          myth.
        </CommonParagraph>
        <CommonParagraph>
          In the community, educated people spread the light of knowledge and
          positive thought to eliminate the darkness. But our cities always
          depressed the citizen with its ugly looks. We all have acquired our
          knowledge and skills that are helping us to make better decisions in
          our life in professional and personal levels during the various stage
          of life. Our generation is suffering from pollution from political
          agendas to environmental circumstances.
        </CommonParagraph>
        <CommonParagraph>
          Therefore, by spreading the environmental knowledge and practice among
          the people by real action is necessary in current context. We have to
          restore love for the nature in our heart.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Executive Summary</ParagraphHeader>
        <CommonParagraph>
          14th February, Valentine's Day, a day set aside for love, world
          celebrate love between two hearts. Being environment activist we are
          celebrating as love between a heart and the nature, “Love The Nature”
        </CommonParagraph>
        <CommonParagraph>
          We, SAVE THE PLANET NEPAL, are demanding 14th February as national
          nature lovers’ day with Government of Nepal.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Promotion</ParagraphHeader>
        <CommonParagraph>
          We are promoting Love The Nature by means of five major activities.
          Such as:
        </CommonParagraph>
        <OrderedCommonList>
          <CommonListItem>
            <em>Speech Campaign</em>: To collect the thought.
          </CommonListItem>
          <CommonListItem>
            <em>Art (drawing/playing) Campaign</em>: To collect the creativity and innovation.
          </CommonListItem>
          <CommonListItem>
            <em>Signature Campaign</em>: To collect the commitments.
          </CommonListItem>
          <CommonListItem>
            <em>Clean & Green Campaign</em>: To maintain surrounding health.
          </CommonListItem>
          <CommonListItem>
            <em>Media Campaign</em>: To aware public and bring replication.
          </CommonListItem>
        </OrderedCommonList>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_LTN)} content={content} />;
}

export default LoveTheNature;
