import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_VOLUNTEERS } from '../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function Volunteers(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <img
          src="http://www.savetheplanet.org.np/uploads/pages/VQSLAiRKb8NWSgrueC3kGjlp-ZH_dRJW.jpg"
          alt="School Green"
          style={{ width: '100%', height: 'auto', borderRadius: '1em' }}
        />
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Green and White volunteering programs</ParagraphHeader>
        <CommonParagraph>
          STPN to provide the volunteering program especially in mountain and
          Himalayan region for national and international interested person.
          When participant enroll in volunteer in mountain and Himalayan region
          , they get to not only see a spectacular country of mountains ,hills,
          valley, deep ravines and forests, they'll also enjoy warm and ever
          welcoming hospitalities of the people and work in volunteer project
          that will uplift poor and deprived communities. In this programs, you
          can teach English to children, take the classes in school student and
          teachers about the environment, sustainable agriculture, local
          resources, or work in orphanage to help orphaned children ,or
          volunteer in health. Nepal perhaps is the most ideal destination for
          volunteering abroad trip. This is so because while volunteering in
          Nepal, you'll also meet warm and friendly people and encounter
          fascinating cultures and unique customs that have thrived in this
          rugged but beautiful country.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>
          Why You Should Join Green white Program
        </ParagraphHeader>
        <CommonParagraph>
          If you love unspoiled nature, the beauty of snow-capped mountains, and
          enjoy living in a region inhabited by warm and welcoming people then
          you must volunteer in the Everest region. For centuries the Sherpas,
          the local people of the region, have lived a hard life walking up and
          down high mountain passes for trade with outside world and for bearing
          extremely cold weather to grow vegetables in the fields during short
          summer months. With the arrival of tourist from around the world, this
          has seen their lives improve a great deal. Now, the region has schools
          and hospitals, and even during winter, there is food and warm clothes.
          But the region needs help from volunteers to progress more as those
          Sherpas who aren’t involved in tourism, and other ethnic groups who
          have moved to the region, are struggling to sustain themselves. By
          teaching and helping their children and community, you will give them
          confidence and a reason to hope for a better future.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_VOLUNTEERS)} content={content} />;
}

export default Volunteers;
