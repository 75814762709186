import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_MV } from '../../common/translation';
import {
  CommonParagraph,
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function MissionAndVision(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>MISSION</ParagraphHeader>
        <CommonParagraph>
          Make Nepal a beautiful and prosperous in terms of environmental
          conservation by mobilizing the local, national and international
          resources so far and promote sustainable development.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>VISION</ParagraphHeader>
        <CommonParagraph>
          Conserve, develop and utilize the natural resources sustainably and
          keep balance in biological diversities in maximal, create a balanced
          nature on the basis of the right of the declining plants and animals.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_MV)} content={content} />;
}

export default MissionAndVision;
