import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_OG } from '../../common/translation';
import {
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
  CommonParagraph,
} from '../../common/style/index.style';

function OurGoalsAndObjective(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>GOALS</ParagraphHeader>
        <CommonParagraph>
          Protect environment, conserve natural resources, keep beauty of the
          planet and create a conducive environment to live in for the future
          generation.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>OBJECTIVES:</ParagraphHeader>
        <OrderedCommonList>
          <CommonListItem>
            To conserve and promote extinctive animals and plants.
          </CommonListItem>
          <CommonListItem>
            To empower people of all classes, levels and areas to conserve
            biological diversities.
          </CommonListItem>
          <CommonListItem>
            To conserve, develop and widen plant community.
          </CommonListItem>
          <CommonListItem>
            Use fundamental principle of livelihood to conserve natural
            resources and utility.
          </CommonListItem>
          <CommonListItem>
            To construct profitless, to keep natural balance by utilizing the
            resources of educational institutions, health&nbsp;posts and develop
            public parks to inform and comfort to different community.
          </CommonListItem>
          <CommonListItem>
            To conduct world-wide programmes related to environmental balance
            and conserve by 2020 A.D.
          </CommonListItem>
          <CommonListItem>
            To establish and operate centers in research of natural resources
            that can be produced in the concerned&nbsp;areas by advocating and
            supporting Human Rights.
          </CommonListItem>
          <CommonListItem>
            To conduct specific self dependence program focusing women’s and
            children’s potential and aware them to&nbsp;generate income by
            maintaining the environmental conservation.
          </CommonListItem>
        </OrderedCommonList>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_OG)} content={content} />;
}

export default OurGoalsAndObjective;
